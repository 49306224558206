import platform from 'platform';

import {setCookie} from './session';

export const DevicePayload = {
  deviceId: process.browser ? window.deviceID || 'web_app' : 'web_app',
  deviceMake: platform.manufacturer || 'web',
  deviceModel: platform.product || 'web',
  deviceTypeCode: 3,
  deviceOs: platform.os.family + '-' + platform.os.version + '-web' || 'Windows-10-web',
  browserVersion: platform.version || '89.0.4389.90',
};

// set auth data
export const setAuthData = (data) => {
  try {
    setCookie('token', data.accessToken);
    setCookie('refreshToken', data.refreshToken);
    setCookie('accessExpiry', data.accessExpireAt);
    setCookie('language', 'en');
  } catch (e) {
    console.error('fail to save data', e);
  }
};
