/* eslint-disable react-hooks/exhaustive-deps */
import Head from 'next/head';
import React from 'react';

import '../../public/css/global-theme.css';
import LoaderHOC from '../hoc/loaderHOC';
import {APP_NAME} from '../lib/config';
import {getCookiees, setCookie} from '../lib/session';
import {guestLogin} from '../services/verification';
import '../styles/globals.css';

function MyApp({Component, pageProps, token}) {
  const [loader, setLoader] = React.useState(false);

  const startLoader = () => setLoader(true);
  const stopLoader = () => setLoader(false);

  React.useEffect(() => {
    setCookie('token', decodeURIComponent(token));
  }, []);

  return (
    <>
      <Head>
        <title>{APP_NAME}</title>
      </Head>
      <LoaderHOC loader={loader} />
      <Component token={token} startLoader={startLoader} stopLoader={stopLoader} {...pageProps} />
    </>
  );
}

MyApp.getInitialProps = async ({Component, ctx}) => {
  let pageProps = {};
  if (Component && Component.getInitialProps) {
    pageProps = await Component.getInitialProps({ctx});
  }
  let token = getCookiees('token', ctx.req);
  if (!token || token === 'undefined') {
    const guestData = await guestLogin();
    token = guestData.token;
  }
  return {pageProps, token};
};

export default MyApp;
