const env = typeof __CONFIG === 'object' ? __CONFIG : process.env || {}; // see _document.js

// App Config
export const API_URL = `${env.NEXT_PUBLIC_API_URL}/v1`;
export const USER_NAME = 'transfans';
export const PASSWORD = 'admin@transfans.com';
export const APP_NAME = 'Trans Fans';
export const APP_LOGO = '/images/favicon.png';
export const WEB_LOGIN_PAGE = `${env.NEXT_PUBLIC_WEBSITE_URL}/login`;

// Assets
export const Arrow_icon = '/images/arrow-back.svg';
export const key_icon = '/images/key-icon.svg';
export const link_expired = '/images/link_expired.png';
export const VERIFY_EMAIL = '/images/verify-email.png';
