import {DevicePayload, setAuthData} from '../lib/global';
import {basicAuth, deleteRequest, getURL, post} from '../lib/request';
import {getCookiees} from '../lib/session';

// guest login
export const guestLogin = async () => {
  const token = getCookiees('token');
  const lang = getCookiees('language');
  if (!token || token === 'undefined') {
    try {
      const reqPayload = {
        ...DevicePayload,
      };
      let data = await fetch(getURL('/guestLogin'), {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: basicAuth,
          lan: lang || 'en',
        }),
        body: JSON.stringify(reqPayload),
      });
      data = await data.json();
      process.browser && setAuthData(data.data);

      return {
        token: data.data.accessToken,
      };
    } catch (e) {
      console.error(e);
      return {
        token: '',
      };
    }
  }
  return {
    token: token,
  };
};

export const resetPasswordAPI = async (data) => {
  return post('/resetPassword', data);
};

export const postVerifyEmail = async (data, extraHeaders = {}) => {
  return post('/verifyEmail', data, extraHeaders);
};

export const postVerifyUsername = async (data, extraHeaders = {}) => {
  return post('/userName/confirm', data, extraHeaders);
};

//check Mail Expire
export const checkMailExpire = async (payload, extraHeaders = {}) => {
  return post(`/verifyLink`, payload, extraHeaders);
};

export const deleteUser = async (data, otherHeaders) => {
  return deleteRequest(
    `/userAccount?deleteConfirmation=${data.deleteConfirmation}&token=${data.token}`,
    otherHeaders
  );
};
