import axios from 'axios';
import btoa from 'btoa';

import {guestLogin} from '../services/verification';
import {API_URL, PASSWORD, USER_NAME} from './config';
import {getCookie, removeCookie} from './session';

export const getURL = (endpoint) => API_URL + endpoint;

export const basicAuth = 'Basic ' + btoa(USER_NAME + ':' + PASSWORD);

const commonHeader = {
  'Content-Type': 'application/json',
  lan: 'en',
};

const mainApi = axios.create();

mainApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 406)) {
      const originalRequest = error.config;

      if (!originalRequest._retry) {
        originalRequest._retry = true;
        try {
          removeCookie('token');
          const {token} = await guestLogin();
          originalRequest.headers['authorization'] = decodeURIComponent(token);

          return axios(originalRequest);
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          return refreshError;
        }
      }
    }
    return Promise.reject(error);
  }
);

export const get = (endpoint, data, headers = {}) => {
  mainApi.defaults.headers.common['authorization'] = decodeURIComponent(getCookie('token', ''));
  return mainApi.get(getURL(endpoint), {
    headers: {...commonHeader, ...headers},
    params: data,
  });
};

export const post = (endpoint, data, headers = {}) => {
  mainApi.defaults.headers.common['authorization'] = decodeURIComponent(getCookie('token', ''));
  return mainApi.post(getURL(endpoint), data, {
    headers: {...commonHeader, ...headers},
  });
};

export const patch = (endpoint, data) => {
  mainApi.defaults.headers.common['authorization'] = decodeURIComponent(getCookie('token', ''));
  return mainApi.patch(getURL(endpoint), data, {
    headers: commonHeader,
  });
};

export const deleteRequest = async (endpoint, data, headers = {}) => {
  mainApi.defaults.headers.common['authorization'] = decodeURIComponent(getCookie('token', ''));
  return mainApi.delete(getURL(endpoint), {
    headers: {...commonHeader, ...headers},
    data: data,
  });
};
