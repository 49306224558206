import React from 'react';
import {RingLoader} from 'react-spinners';

const loaderHOC = ({loader}) => {
  return (
    <>
      {loader && (
        <div className="loader-container position-fixed vh-100 vw-100 d-flex align-items-center justify-content-center">
          <RingLoader loading={loader} color="var(--themeColor)" size={80} />
        </div>
      )}
      <style jsx="true">
        {`
          .loader-container {
            backdrop-filter: blur(3px);
            z-index: 1;
          }
        `}
      </style>
    </>
  );
};

export default loaderHOC;
